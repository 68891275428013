<template>
  <div class="h-32 p-2">
    <MevcutLink
      class="widthCard h-full bg-bg_alt_color bg-opacity-30 flex text-black/70  cursor-pointer text-[0.65rem] rounded-[14px] sm:text-xs"
      :with-branch-id-query="false"
      :to="enableNavigate ? storeRoute(storeLocal,isDelivering) : undefined"
    >
      <div class="absolute top-3 ltr:right-3 rtl:left-3">
        <div
          class="flex items-center px-2 py-1 mb-1 rounded-lg bg-green-100"
          :class="
            storeLocal.isStoreAvailableToBuy ? 'bg-green-100' : 'bg-red-100'
          "
        >
          <div
            class="w-2.5 h-2.5 mr-1.5 rounded-full"
            :class="
              storeLocal.isStoreAvailableToBuy ? 'bg-green-500' : 'bg-red-500'
            "
          ></div>
          <span
            class="text-xs"
            :class="
              storeLocal.isStoreAvailableToBuy
                ? 'text-green-600'
                : 'text-red-600'
            "
          >{{
            storeLocal.isStoreAvailableToBuy ? t('opened') : t('closed')
          }}</span>
        </div>
      </div>
      <div class="aspect-[1] p-py flex-shrink-0">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="storeLocal.icon"
          :alt="storeLocal.name"
          width="110"
          height="110"
          class="w-full h-full rounded-[14px] object-fill"
        />
      </div>
      <div class="w-4"></div>
      <div class="flex-shrink-1">
        <div class="h-8"></div>
        <span class="font-bold text-[16px] break-words">
          {{ storeLocal.name }}
        </span>
        <div class="h-2"></div>
        <div
          class="break-words line-clamp-2"
          v-html="storeLocal.about"
        ></div>
      </div>
      <div class="w-4"></div>
    </MevcutLink>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~~/composables/useMenuModel'
const { storeModel } = useMenuModel()
const props = withDefaults(
  defineProps<{
    store: Store
    enableNavigate?: boolean
    isDelivering?: boolean
  }>(),
  {
    enableNavigate: true
  }
)

const { storeRoute } = useDomainState()
const storeLocal = storeModel(props.store)
const { t } = useI18n()
</script>

<style>
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.custom-bg {
  @apply bg-bg_color text-text_color !important;
}
.widthCard {
  width: 80vw !important;
}
@media (min-width: 650px) {
  .widthCard {
    width: 40vw !important;
  }
}
@media (min-width: 750px) {
  .widthCard {
    width: 20rem !important;
  }
}
</style>
